@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --media-spacing-114: 114px;
  --media-spacing-32: 32px;
  --media-spacing-28: 28px;
  --media-spacing-24: 24px;
  --media-spacing-16: 16px;
  --media-spacing-12: 12px;

  --media-primary: 4, 135, 108; /* #04876C */

  --media-white: 255, 255, 255; /* #fff */
  --media-black: 0, 0, 0; /* #000 */
  --media-default: 17, 25, 39, 1;
  --media-gray-800: 31, 42, 55;
  --media-gray-700: 56, 66, 80; /* #384250*/
  --media-gray-500: 108, 115, 127;
  --media-gray-400: 157, 164, 174;
  --media-gray-200: 236, 238, 242; /* #ECEEF2 */
  --media-gray-100: 243, 244, 246;
  --media-gray-50: 249, 250, 251; /* #F9FAFB */

  --media-green-25: 246, 254, 251; /* #F6FEFB */
  --media-green-50: 237, 252, 245; /* #EDFCF5 */
  --media-green-100: 211, 248, 232; /* #D3F8E8 */
  --media-green-200: 166, 240, 211; /* #A6F0D3 */
  --media-green-300: 95, 232, 182; /* #5FE8B6 */
  --media-green-400: 42, 212, 158; /* #2AD49E */
  --media-green-500: 18, 184, 142; /* #12B88E */
  --media-green-600: 10, 150, 122; /* #0A967A */
  --media-green-700: 8, 120, 103; /* #087867 */
  --media-green-800: 9, 92, 82; /* #095C52 */
  --media-green-900: 8, 76, 69; /* #084C45 */

  --button-color-secondary-default-background: var(--media-white);
  --button-color-secondary-default-text: var(--media-gray-700);
  --button-color-secondary-default-border: 208, 213, 221;

  --button-color-primary-default-background: var(--media-primary);
  --button-color-primary-default-text: var(--media-white);
  --button-color-primary-default-border: var(--media-primary);

  --label-color-filled-warning-border: 250, 217, 142; /* #FAD98E */
  --label-color-filled-warning-background: 255, 250, 235; /* #FFFAEB */
  --label-color-filled-warning-text: 181, 71, 8; /* #B54708 */

  --label-color-filled-success-border: 95, 232, 182; /* #5FE8B6 */
  --label-color-filled-success-background: 237, 252, 245; /* #EDFCF5 */
  --label-color-filled-success-text: 8, 120, 103; /* #087867 */

  --semantic-border-muted: var(--media-gray-200);
  --semantic-bg-canvas: var(--media-gray-50);

  --semantic-border-subtle: 243, 244, 246; /* #F3F4F6 */
  --tab-color-white-group-bg: var(--media-gray-50);

  --tab-color-white-border: var(--media-black), 0.4;
  --tab-color-white-background: var(--media-white);

  --sidebar-width: theme(width.56);
  --sidebar-mobile-width: theme(width.12);

  --sidebar-height: 100%;
  --sidebar-mobile-height: 48px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer utilities {
  .spacer {
    flex: 1 1 auto;
  }

  .text-balance {
    text-wrap: balance;
  }

  .typography-body-xs-medium {
    @apply text-xs font-medium not-italic leading-[1.125rem];
  }

  .typography-body-sm-medium {
    @apply text-sm font-medium not-italic;
  }

  .typography-display-md-normal {
    @apply text-4xl font-normal not-italic leading-[2.75rem] tracking-tight;
  }

  .typography-button-lg-medium {
    @apply text-base font-medium not-italic;
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: var(--arrow-size) solid transparent;
  border-right: var(--arrow-size) solid transparent;

  border-top: var(--arrow-size) solid var(--arrow-color);
}

.top-partners-chart .recharts-legend-wrapper {
  @apply !h-full !w-1/2;
}

.top-partners-chart .recharts-legend-item {
  @apply !flex flex-row items-center justify-between;
}

.top-partners-chart .recharts-default-legend {
  @apply space-y-4;
}

.top-partners-chart .recharts-legend-item-text {
  @apply flex grow flex-row justify-between;
}

.recharts-layer,
.recharts-sector {
  outline: none;
}

.recharts-layer:focus,
.recharts-sector:focus {
  stroke: rgba(var(--media-primary));
  stroke-width: 2px;
  stroke-linejoin: round;
}
